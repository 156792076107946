import { createRoot } from 'react-dom/client';

import InfoTpl from 'ui-component/cards/InfoTpl';
import multiYearConfig from './viirsMultiYearConfig';
import { getActiveTypeFilters, getActiveCategoryFilters } from 'utils/filterUtils';
import { popup, generateLayerConfig, getStyle, emitterClickHandler } from 'utils/layerUtils';
import upstream from '../../assets/images/markerIcons/upstream.png';
import coalMine from '../../assets/images/markerIcons/coalMine.png';
import cement from '../../assets/images/markerIcons/cement.png';
import downstream from '../../assets/images/markerIcons/downstream.png';
import greenhouse from '../../assets/images/markerIcons/greenhouse.png';
import landfill from '../../assets/images/markerIcons/landfill.png';
import metallurgy from '../../assets/images/markerIcons/metallurgy.png';
import unique from '../../assets/images/markerIcons/unique.png';
import unknown from '../../assets/images/markerIcons/unknown.png';
import industrial from '../../assets/images/markerIcons/industrial.png';
import volcano from '../../assets/images/markerIcons/volcano.png';
import sawmill from '../../assets/images/markerIcons/sawmill.png';

const viirsYear = {
  config: multiYearConfig,
  id: multiYearConfig.id,
  name: multiYearConfig.name,
  description: multiYearConfig.description,
  enabled: multiYearConfig.enabled,
  metadata: multiYearConfig.metadata,
  requiredAuthLevel: multiYearConfig.requiredAuthLevel,
  tooltipText: multiYearConfig.tooltipText,
  on: {
    load: (map, draw, callback, stateObj) => [
      () => {
        const iconNames = {
          cement,
          'coal mine': coalMine,
          downstream,
          greenhouse,
          industrial,
          landfill,
          metallurgy,
          sawmill,
          unique,
          unknown,
          upstream,
          volcano
        };
        const { layerState, filterState } = stateObj;
        const { circleRadius, circleColor, markerIcon } = getStyle(
          layerState.style,
          multiYearConfig
        );
        if (map.getSource(multiYearConfig.id) === undefined) {
          map.addSource(multiYearConfig.id, multiYearConfig.layerSource);
        }
        if (map.getLayer(multiYearConfig.id) === undefined && multiYearConfig.enabled) {
          // Loading the local icons and adding them to the map
          Object.entries(iconNames).forEach(([type, path]) => {
            if (type === 'volcano') {
              // Because Mapbox has a built-in volcano icon that we need to remove
              if (map.hasImage('volcano')) {
                map.removeImage('volcano');
              }
            }
            if (!map.hasImage(type)) {
              map.loadImage(path, (error, image) => {
                if (error) {
                  console.error(`Error loading image for type: ${type}`, error);
                  return;
                }
                map.addImage(type, image, { overwrite: true });
              });
            }
          });
          map.addLayer(
            generateLayerConfig(
              multiYearConfig.id,
              multiYearConfig.layerType,
              multiYearConfig.paint(circleRadius, circleColor),
              multiYearConfig.id,
              multiYearConfig.sourceLayer,
              multiYearConfig.metadata,
              multiYearConfig.layout(markerIcon, circleRadius)
            )
          );

          let countryFilter = null;
          const { selectedCountry } = filterState;
          if (filterState && selectedCountry && selectedCountry.alpha3) {
            countryFilter = ['==', 'iso', selectedCountry.alpha3];
          }

          let typeFilter = null;
          const activeTypeFilters = getActiveTypeFilters(filterState);
          if (activeTypeFilters.length) {
            typeFilter = ['in', 'type', ...activeTypeFilters];
          }

          let categoryFilter = null;
          const activeCategoryFilters = getActiveCategoryFilters(filterState);
          if (activeCategoryFilters.length) {
            categoryFilter = ['in', 'category', ...activeCategoryFilters];
          }

          const layersFilter = ['all'];
          if (countryFilter || typeFilter || categoryFilter) {
            if (countryFilter) {
              layersFilter.push(countryFilter);
            }
            if (typeFilter) {
              layersFilter.push(typeFilter);
            }
            if (categoryFilter) {
              layersFilter.push(categoryFilter);
            }
          }

          if (layersFilter.length > 1 && map.getLayer(multiYearConfig.id)) {
            map.setFilter(multiYearConfig.id, layersFilter);
          }
        }
        if (multiYearConfig.sublayers.length > 0) {
          multiYearConfig.sublayers.forEach((sublayer) => {
            if (map.getLayer(sublayer.id) === undefined) {
              map.addLayer(
                generateLayerConfig(
                  sublayer.id,
                  sublayer.layerType,
                  sublayer.paint(),
                  multiYearConfig.id,
                  sublayer.sourceLayer,
                  sublayer.metadata
                )
              );
            }
          });
        }
      }
    ],
    click: (map, draw, callback) => [
      // The event is to make the selected layer highlight through pulsing
      multiYearConfig.id,
      (event) => emitterClickHandler(event, multiYearConfig, map, draw, callback)
    ],
    mouseenter: (map, draw, callback, stateObj) => [
      multiYearConfig.id,
      (event) => {
        const { properties } = event.features[0];
        const { lat, lon } = properties;
        const { id: layerId } = multiYearConfig;
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = [lon, lat].slice();
        if (properties) {
          const placeHolder = document.createElement('div');
          const root = createRoot(placeHolder); // Use createRoot here
          root.render(
            <InfoTpl
              properties={{ ...properties, layerId }}
              countries={stateObj.filterState.countries}
            />
          );

          popup.setDOMContent(placeHolder).setLngLat(coordinates).addTo(map);
        }
      }
    ],
    mouseleave: (map) => [
      multiYearConfig.id,
      () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
      }
    ]
  }
};

export default viirsYear;
