import maplibregl from 'maplibre-gl';
import { axiosApi } from 'utils/axios';

import { LayerStylesEnum } from 'store/slices/layers';
import { detectionsLayerConfig, addDetections } from 'config/layers/detectionsLayer';
import {
  dotPointBufferLayerConfig,
  dotPointBufferLayerRingConfig,
  dotPointLayerConfig,
  addPulse,
  addPulseBuffer
} from 'config/layers/pulseLayer';
import {
  viirsLayer,
  viirsMultiYearLayer,
  viirs2022Layer,
  viirs2023Layer,
  landsatLayer
} from 'config/layers';
import { removeSelectedDrawMeasure, updateDrawMeasure } from './getDrawMeasure';
import { temperatureStyle } from 'config/layers/styles';

// const layerIds = [viirsLayer.id, viirsMultiYearLayer.id, viirs2022Layer.id ];

const getLayerProperty = (layerObject, property, emitterId = '') => {
  switch (property) {
    case 'queryType':
      return layerObject.config.resourceId;
    case 'tileUrl':
      return layerObject.config.tileSource;
    case 'profileUrl':
      return layerObject.config.profileUrl(emitterId);
    case 'csvUrl':
      return layerObject.config.csvUrl(emitterId);
    default:
      return null;
  }
};

const getProperty = (layerId, property, emitterId = '') => {
  let layerObject;
  switch (layerId) {
    case viirsLayer.id:
      layerObject = viirsLayer;
      break;
    case viirs2022Layer.config.id:
      layerObject = viirs2022Layer;
      break;
    case viirs2023Layer.id:
      layerObject = viirs2023Layer;
      break;
    case landsatLayer.id:
      layerObject = landsatLayer;
      break;
    case viirsMultiYearLayer.id:
    default:
      layerObject = viirsMultiYearLayer;
      break;
  }
  return getLayerProperty(layerObject, property, emitterId);
};

const getChartUrlFromLayerId = (layerId, emitterId, type = 'yearly') => {
  const typeVar = type === 'yearly' ? 'year' : 'month';
  const queryType = getProperty(layerId, 'queryType');
  // const queryType = layerId === viirsMultiYearLayer.id ? 'multiyear_vnf' : 'y2020';
  return `${process.env.REACT_APP_API_URL}/series/${typeVar}/${queryType}/${emitterId}`;
};

const getPolygonFeatures = (draw) => {
  const featureCollection = draw.getAll();
  featureCollection.features = featureCollection.features.filter(
    (feature) => feature.geometry.type === 'Polygon'
  );
  return featureCollection.features.length ? featureCollection : null;
};

const deleteExtraPolygons = (draw) => {
  const featureCollection = getPolygonFeatures(draw);
  if (featureCollection?.features?.length > 1) {
    const featureIds = featureCollection.features.map((feature) => feature.id);
    featureIds.pop();
    draw.delete(featureIds);
  }
  return getPolygonFeatures(draw);
};

const getCSVUrlFromConfig = (layerId, emitterId) => getProperty(layerId, 'csvUrl', emitterId);

// eslint-disable-next-line no-return-assign
const getProfileUrlFromLayerId = (layerId, emitterId) =>
  getProperty(layerId, 'profileUrl', emitterId);

// const getProfileUrlFromLayerId = (layerId, emitterId) => {
//   if (layerId === viirsMultiYearLayer.id) {
//     return viirsMultiYearLayer.config.profileUrl(emitterId);
//   }
//   if (layerId === viirsLayer.id) {
//     return viirsLayer.config.profileUrl(emitterId);
//   }
//   return null;
// };

const getCsvUrlFromLayerId = (layerId, emitterId, type = 'static') => {
  switch (type) {
    case 'month':
    case 'year':
      if (
        [
          viirsMultiYearLayer.id,
          viirsLayer.id,
          viirs2022Layer.id,
          viirs2023Layer.id,
          landsatLayer.id
        ].includes(layerId)
      ) {
        // if (layerIds.includes(layerId)) {
        const queryType = getProperty(layerId, 'queryType');
        // const queryType = layerId === viirsMultiYearLayer.id ? 'multiyear_vnf' : 'y2020';
        return `${process.env.REACT_APP_API_URL}/series/${type}/${queryType}/${emitterId}?format=csv`;
      }
      break;
    case 'static':
    default:
      // if (layerIds.includes(layerId)) {
      if (
        [
          viirsMultiYearLayer.id,
          viirsLayer.id,
          viirs2022Layer.id,
          viirs2023Layer.id,
          landsatLayer.id
        ].includes(layerId)
      ) {
        // return layerId === viirsMultiYearLayer.id
        //   ? viirsMultiYearLayer.config.csvUrl(emitterId)
        //   : viirsLayer.config.csvUrl(emitterId);
        // const queryType = layerId === viirsMultiYearLayer.id ? 'multiyear_vnf' : 'y2020';
        const queryType = getProperty(layerId, 'queryType');
        return `${process.env.REACT_APP_API_URL}/series/${type}/${queryType}/${emitterId}?format=file`;
      }
      break;
  }
  return null;
};

const reloadVNFLayer = (map, layerId) => {
  if (
    [
      viirsMultiYearLayer.id,
      viirsLayer.id,
      viirs2022Layer.id,
      viirs2023Layer.id,
      landsatLayer.id
    ].includes(layerId)
  ) {
    // const tileUrl =
    //   layerId === viirsMultiYearLayer.id
    //     ? viirsMultiYearLayer.config.tileSource
    //     : viirsLayer.config.tileSource;
    // if (layerIds.includes(layerId)) {
    const tileUrl = getProperty(layerId, 'tileUrl');

    map.getSource(layerId).setTiles([`${tileUrl}?_dc=${Date.now()}`]);
  }
};

const updateVNFFilter = (map, layerId, data, tileUrl, dispatch, setSelectionToolCoordinates) => {
  let drawData = null;
  try {
    drawData = JSON.stringify(data.features[0].geometry);
  } catch (err) {
    drawData = null;
  }
  if (drawData) {
    map.getSource(layerId).setTiles([`${tileUrl}?filter=${drawData}`]);
    dispatch(setSelectionToolCoordinates(drawData));
  } else {
    map.getSource(layerId).setTiles([`${tileUrl}`]);
    dispatch(setSelectionToolCoordinates(null));
  }
};

const popup = new maplibregl.Popup({
  closeButton: false,
  closeOnClick: true
});

const generateLayerConfig = (id, type, paint, source, sourceLayer, metadata, layout = {}) => ({
  id, // Layer ID
  type,
  paint,
  source, // ID of the tile source (the first arguement in map.addSource() - probably same as layer ID)
  'source-layer': sourceLayer, // Source has several layers. We visualize the one with name 'sequence'.
  metadata,
  layout
});

const getStyle = (style, config, isRadiantHeat = false) => {
  switch (style) {
    case LayerStylesEnum.Type: {
      const typeStyle = { ...config.styles?.typeStyle };
      if (isRadiantHeat && typeStyle) {
        if (config.styles?.energyStyle?.circleRadius) {
          typeStyle.circleRadius = config.styles?.energyStyle?.circleRadius;
        }
      }
      return typeStyle;
    }
    case LayerStylesEnum.Temp: {
      const tempStyle = { ...config.styles?.temperatureStyle };
      if (isRadiantHeat && tempStyle) {
        if (config.styles?.energyStyle?.circleRadius) {
          tempStyle.circleRadius = config.styles?.energyStyle?.circleRadius;
        }
      }
      return tempStyle;
    }
    case LayerStylesEnum.Energy:
      return config.styles?.energyStyle;
    default:
      return config.styles?.typeStyle;
  }
};

const removeDetectionsLayerIf = (map, type = 'dot') => {
  const detectionsLayer = map.getLayer(detectionsLayerConfig[type].id);
  if (detectionsLayer) {
    map
      .removeLayer(detectionsLayerConfig[type].id)
      .removeSource(detectionsLayerConfig[type].source);
  }
};

const removePulseLayerBufferIf = (map, type = 'dot') => {
  const dotBufferLayer = map.getLayer(dotPointBufferLayerConfig[type].id);
  if (dotBufferLayer) {
    map
      .removeLayer(dotPointBufferLayerRingConfig[type].id)
      .removeLayer(dotPointBufferLayerConfig[type].id)
      .removeSource(dotPointBufferLayerConfig[type].source);
  }
};

const removePulseLayerIf = (map, type = 'dot') => {
  const dotLayer = map.getLayer(dotPointLayerConfig[type].id);
  if (dotLayer) {
    map.removeLayer(dotPointLayerConfig[type].id).removeSource(dotPointLayerConfig[type].source);
  }
};

const getPulsingDot = (
  map,
  size = 100,
  { outerR, outerG, outerB } = { outerR: 255, outerG: 200, outerB: 200 },
  { innerR, innerG, innerB } = { innerR: 255, innerG: 100, innerB: 100 }
) => {
  const ret = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),
    onAdd() {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },
    render() {
      const duration = 1000;
      const t = (performance.now() % duration) / duration;

      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;
      const context = this.context;
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = `rgba(${outerR}, ${outerG}, ${outerB}, ${1 - t})`;
      context.fill();

      // Draw the inner circle.
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = `rgba(${innerR}, ${innerG}, ${innerB}, 1)`;
      context.strokeStyle = 'white';
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      map.triggerRepaint();

      // Return `true` to let the map know that the image was updated.
      return true;
    }
  };
  return ret;
};

const handleMarker = async (event, map /* , config */, draw) => {
  const type = 'random';
  if (event?.features?.[0]?.geometry?.type === 'Point') {
    const pulsingDot = getPulsingDot(
      map,
      100,
      { outerR: 200, outerG: 200, outerB: 255 },
      { innerR: 100, innerG: 100, innerB: 255 }
    );
    const [lon, lat] = event.features[0].geometry.coordinates;
    removePulseLayerIf(map, type);
    removePulseLayerBufferIf(map, type);
    // Adding the detections temperature GeoJSON layer
    removeDetectionsLayerIf(map, type);
    if (dotPointLayerConfig[type].lastPulseId !== event.features[0].id) {
      // const { id: layerId } = config;
      addPulse(map, lat, lon, pulsingDot, type, null, draw);
      addPulseBuffer(map, [lon, lat] /* , layerId */, type);
      dotPointLayerConfig[type].lastPulseId = event.features[0].id;
      const { data } = await axiosApi.get(`/detections/${lat}/${lon}`);
      // console.log(data);
      addDetections(map, data, temperatureStyle, type /* , layerId */);
    } else {
      dotPointLayerConfig[type].lastPulseId = undefined;
      detectionsLayerConfig[type].paint = {
        'circle-color': '#001ab2',
        'circle-radius': 4
      };
    }
  }
};

const emitterClickHandler = async (event, config, map, draw, callback) => {
  const type = 'dot'; // to specify that this is port of the dot (red pulsing) layer
  const { properties } = event?.features.length
    ? event.features[0]
    : { err: 'no properties found' };
  const pulsingDot = getPulsingDot(map);

  const { lat, lon } = properties;
  removePulseLayerIf(map, type);
  // Adding the detections temperature GeoJSON layer
  removeDetectionsLayerIf(map, type);
  if (dotPointLayerConfig[type].lastPulseId !== properties.id) {
    const { id: layerId } = config;
    addPulse(map, lat, lon, pulsingDot, type, layerId);
    dotPointLayerConfig[type].lastPulseId = properties.id;
    callback({ ...properties, layerId });
    try {
      const { data } = await axiosApi.get(`/detections/${lat}/${lon}`);
      // console.log('GeoJSON Data:', data);
      addDetections(map, data, config.styles?.temperatureStyle, 'dot', layerId);
    } catch (error) {
      console.error('Error:', error);
    }
  } else {
    dotPointLayerConfig[type].lastPulseId = undefined;
    detectionsLayerConfig[type].paint = {
      'circle-color': '#001ab2',
      'circle-radius': 4
    };
    callback(null);
  }
};

const isAllowedType = (event) => {
  const allowedTypes = ['LineString'];
  if (
    event.features &&
    event.features.length > 0 &&
    allowedTypes.includes(event.features[0].geometry.type)
  ) {
    return true;
  }
  return false;
};

const drawCreate = ({ event, layerConfig, map, draw, dispatch, setSelectionToolCoordinates }) => {
  if (isAllowedType(event)) {
    updateDrawMeasure(event.features, dispatch);
  } else {
    handleMarker(event, map, draw);
    const featureCollection = deleteExtraPolygons(draw);
    if (featureCollection) {
      layerConfig.forEach((layer) => {
        const layerConfig = layer.config;
        updateVNFFilter(
          map,
          layerConfig.id,
          featureCollection,
          layerConfig.tileSource,
          dispatch,
          setSelectionToolCoordinates
        );
      });
    }
  }
};

const drawUpdate = ({ event, layerConfig, map, draw, dispatch, setSelectionToolCoordinates }) => {
  if (isAllowedType(event)) {
    updateDrawMeasure(event.features, dispatch);
  } else {
    const featureCollection = getPolygonFeatures(draw);
    if (featureCollection) {
      layerConfig.forEach((layer) => {
        const layerConfig = layer.config;
        updateVNFFilter(
          map,
          layerConfig.id,
          featureCollection,
          layerConfig.tileSource,
          dispatch,
          setSelectionToolCoordinates
        );
      });
    } else {
      layerConfig.forEach((layer) => {
        const layerConfig = layer.config;
        map.getSource(layerConfig.id).setTiles([layerConfig.tileSource]);
      });
      dispatch(setSelectionToolCoordinates(null));
    }
  }
};

const drawDelete = ({ event, layerConfig, map, dispatch, setSelectionToolCoordinates }) => {
  if (isAllowedType(event)) {
    removeSelectedDrawMeasure(event.features, dispatch);
  } else {
    layerConfig.forEach((layer) => {
      const layerConfig = layer.config;
      map.getSource(layerConfig.id).setTiles([layerConfig.tileSource]);
    });
    dispatch(setSelectionToolCoordinates(null));
  }
};

const filterStyleColors = (colors, filterList) =>
  colors.filter((color) => filterList.includes(color.name));

export {
  deleteExtraPolygons,
  drawCreate,
  drawUpdate,
  drawDelete,
  popup,
  getChartUrlFromLayerId,
  getPolygonFeatures,
  getStyle,
  handleMarker,
  reloadVNFLayer,
  updateVNFFilter,
  filterStyleColors,
  removeDetectionsLayerIf,
  removePulseLayerIf,
  removePulseLayerBufferIf,
  generateLayerConfig,
  emitterClickHandler,
  getCsvUrlFromLayerId,
  getProfileUrlFromLayerId,
  getPulsingDot,
  getCSVUrlFromConfig
};
