import { createRoot } from 'react-dom/client';

import InfoTpl from 'ui-component/cards/InfoTpl';
import { getActiveTypeFilters, getActiveCategoryFilters } from 'utils/filterUtils';
import { popup, generateLayerConfig, getStyle, emitterClickHandler } from 'utils/layerUtils';
import newIcon from '../../assets/images/markerIcons/new.png';
import landsatConfig from './landsatConfig';

const landsat = {
  config: landsatConfig,
  id: landsatConfig.id,
  name: landsatConfig.name,
  description: landsatConfig.description,
  enabled: landsatConfig.enabled,
  metadata: landsatConfig.metadata,
  requiredAuthLevel: landsatConfig.requiredAuthLevel,
  tooltipText: landsatConfig.tooltipText,
  on: {
    load: (map, draw, callback, stateObj) => [
      () => {
        const iconNames = {
          new: newIcon
        };
        const { layerState, filterState } = stateObj;
        const { circleRadius, circleColor, markerIcon } = getStyle(layerState.style, landsatConfig);
        if (map.getSource(landsatConfig.id) === undefined) {
          map.addSource(landsatConfig.id, landsatConfig.layerSource);
        }
        if (map.getLayer(landsatConfig.id) === undefined && landsatConfig.enabled) {
          // Loading the local icons and adding them to the map
          Object.entries(iconNames).forEach(([type, path]) => {
            if (!map.hasImage(type)) {
              map.loadImage(path, (error, image) => {
                if (error) throw error;
                map.addImage(type, image);
              });
            }
          });
          map.addLayer(
            generateLayerConfig(
              landsatConfig.id,
              landsatConfig.layerType,
              landsatConfig.paint(circleRadius, circleColor),
              landsatConfig.id,
              landsatConfig.sourceLayer,
              landsatConfig.metadata,
              landsatConfig.layout(markerIcon, circleRadius)
            )
          );

          let countryFilter = null;
          const { selectedCountry } = filterState;
          if (filterState && selectedCountry && selectedCountry.alpha3) {
            countryFilter = ['==', 'iso', selectedCountry.alpha3];
          }

          let typeFilter = null;
          const activeTypeFilters = getActiveTypeFilters(filterState);
          if (activeTypeFilters.length) {
            typeFilter = ['in', 'type', ...activeTypeFilters];
          }

          let categoryFilter = null;
          const activeCategoryFilters = getActiveCategoryFilters(filterState);
          if (activeCategoryFilters.length) {
            categoryFilter = ['in', 'category', ...activeCategoryFilters];
          }

          const layersFilter = ['all'];
          if (countryFilter || typeFilter || categoryFilter) {
            if (countryFilter) {
              layersFilter.push(countryFilter);
            }
            if (typeFilter) {
              layersFilter.push(typeFilter);
            }
            if (categoryFilter) {
              layersFilter.push(categoryFilter);
            }
          }

          if (layersFilter.length > 1 && map.getLayer(landsatConfig.id)) {
            map.setFilter(landsatConfig.id, layersFilter);
          }
        }
      }
    ],
    click: (map, draw, callback) => [
      // The event is to make the selected layer highlight through pulsing
      landsatConfig.id,
      (event) => emitterClickHandler(event, landsatConfig, map, draw, callback)
    ],
    mouseenter: (map, draw, callback, stateObj) => [
      landsatConfig.id,
      (event) => {
        const { properties } = event.features[0];
        const { lat, lon } = properties;
        const { id: layerId } = landsatConfig;
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = [lon, lat].slice();
        if (properties) {
          const placeHolder = document.createElement('div');
          const root = createRoot(placeHolder); // Use createRoot here
          root.render(
            <InfoTpl
              properties={{ ...properties, layerId }}
              countries={stateObj.filterState.countries}
            />
          );

          popup.setDOMContent(placeHolder).setLngLat(coordinates).addTo(map);
        }
      }
    ],
    mouseleave: (map) => [
      landsatConfig.id,
      () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
      }
    ]
  }
};

export default landsat;
