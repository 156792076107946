import { AuthRoles } from 'store/slices/user';
import { generateTypeStyle, temperatureStyle, energyStyle } from './styles';

const tileSource = `${process.env.REACT_APP_API_URL}/layers/landsat/{z}/{x}/{y}.mvt`;
export const layerTypes = ['new'];
const landsatConfig = {
  id: 'landsat_id',
  name: 'Day Time Landsat Catalog',
  pathRowButtonText: 'Landsat path row',
  description: 'Toggle to enable/disable layer',
  enabled: false, // optional, will default to false if not set here
  requiredAuthLevel: AuthRoles.Public,
  tooltipText: 'Public dataset layer',
  layerType: 'symbol',
  resourceId: 'landsatAlegeriaIraqUsa',
  adminOnly: false,
  profileUrl: (id) => ``,
  csvUrl: (id) => ``,
  metadata: {
    name: 'Landsat Catalog',
    labels: {
      '-': 'Unknown'
    }
  },
  sourceLayer: 'vnf',
  tileSource,
  layerSource: {
    attribution:
      '© <a href="https://eogdata.mines.edu/products/vnf/#credit" target="_blank">Earth Observation Group</a>',
    type: 'vector',
    tiles: [tileSource],
    minzoom: 1,
    maxzoom: 20
  },
  styles: {
    typeStyle: generateTypeStyle(layerTypes),
    temperatureStyle,
    energyStyle
  },
  paint: (radius, color) => ({
    'icon-color': color
  }),
  layout: (icon, size) => ({
    'icon-image': icon,
    'icon-size': size,
    'icon-allow-overlap': true
  }),
  sublayers: [
    {
      id: 'viirs-contour',
      layerType: 'line',
      metadata: {
        name: 'Site Contours',
        labels: {
          '#000': 'Outline (Login Req.)'
        }
      },
      sourceLayer: 'contour',
      paint: () => ({
        'line-color': '#000',
        'line-width': 1
      })
    }
  ]
};

export default landsatConfig;
